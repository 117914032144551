import axios from 'axios';
import Vue from 'vue';
import Vuex from 'vuex';
import StoreMixin from '@/mixins/Store.vue';
import DataMixin from '@/mixins/Data.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);
Vue.use(DataMixin);

const state = {
    data: {},
    dataByUserId: {},
    dataAdmin: {},
    dataProfitPerPlayer: {},
    dataProfitPerNetwork: {},
    dataAverageBuyIn: {},
    topProfitStatisticsByPlayer: [],
    bottomProfitStatisticsByPlayer: [],
    loadingDataAverageBuyIn: false,
    loading: true,
    loadingDataByUserId: true,
    loadingDataAdmin: false, // correct way
    loadingDataProfitPerPlayer: false,
    loadingDataProfitPerNetwork: false,
    loadingTopProfitStatisticsByPlayer: false,
    loadingBottomProfitStatisticsByPlayer: false,


    error: null,
    humanError: null,
    statsStatistics: {},
    loadingStatsStatistics: null,
    lastPayloadDataByUserId: {},
    lastPayloadDataAverageBuyIn: {},
    lastPayloadDataAdmin: {},
    lastCancelToken: null,
};

const getters = {
    data: (state) => state.data,
    statsStatistics: (state) => state.statsStatistics,
    dataByUserId: (state) => state.dataByUserId,
    dataAdmin: (state) => state.dataAdmin,
    dataProfitPerPlayer: (state) => state.dataProfitPerPlayer,
    dataProfitPerNetwork: (state) => state.dataProfitPerNetwork,
    dataAverageBuyIn: (state) => state.dataAverageBuyIn,
    topProfitStatisticsByPlayer: (state) => state.topProfitStatisticsByPlayer,
    bottomProfitStatisticsByPlayer: (state) => state.bottomProfitStatisticsByPlayer,
    loadingStatsStatistics: (state) => state.loadingStatsStatistics,
    loading: (state) => state.loading,
    loadingDataByUserId: (state) => state.loadingDataByUserId,
    loadingDataAdmin: (state) => state.loadingDataAdmin,
    loadingDataProfitPerPlayer: (state) => state.loadingDataProfitPerPlayer,
    loadingDataProfitPerNetwork: (state) => state.loadingDataProfitPerNetwork,
    loadingDataAverageBuyIn: (state) => state.loadingDataAverageBuyIn,
    loadingTopProfitStatisticsByPlayer: (state) => state.loadingTopProfitStatisticsByPlayer,
    loadingBottomProfitStatisticsByPlayer: (state) => state.loadingBottomProfitStatisticsByPlayer,
    error: (state) => state.error,
    humanError: (state) => state.humanError
};

const mutations = {
    setData(state, payload) {
        state.data = payload;
    },
    setStatsStatistics(state, payload) {
        state.statsStatistics = payload;
    },
    setDataByUserId(state, payload) {
        state.dataByUserId = payload;
    },
    setDataAdmin(state, payload) {
        state.dataAdmin = payload;
    },
    setDataProfitPerPlayer(state, payload) {
        state.dataProfitPerPlayer = payload;
    },
    setDataProfitPerNetwork(state, payload) {
        state.dataProfitPerNetwork = payload;
    },
    setTopProfitStatisticsByPlayer(state, payload) {
        state.topProfitStatisticsByPlayer = payload;
    },
    setBottomProfitStatisticsByPlayer(state, payload) {
        state.bottomProfitStatisticsByPlayer = payload;
    },
    setDataAverageBuyIn(state, payload) {
        state.dataAverageBuyIn = payload;
    },
    setLoading(state, payload) {
        state.loading = payload;
    },
    setLoadingDataAverageBuyIn(state, payload) {
        state.loadingDataAverageBuyIn = payload;
    },
    setLoadingDataByUserId(state, payload) {
        state.loadingDataByUserId = payload;
    },
    setLoadingDataAdmin(state, payload) {
        state.loadingDataAdmin = payload;
    },
    setLoadingStatsStatistics(state, payload) {
        state.loadingStatsStatistics = payload;
    },
    setLoadingDataProfitPerPlayer(state, payload) {
        state.loadingDataProfitPerPlayer = payload;
    },
    setLoadingDataProfitPerNetwork(state, payload) {
        state.loadingDataProfitPerNetwork = payload;
    },
    setLoadingTopProfitStatisticsByPlayer(state, payload) {
        state.loadingTopProfitStatisticsByPlayer = payload;
    },
    setLoadingBottomProfitStatisticsByPlayer(state, payload) {
        state.loadingBottomProfitStatisticsByPlayer = payload;  
    },
    setError(state, payload) {
        state.error = payload;
    },
    setHumanError(state, payload) {
        state.humanError = payload;
    },
    setLastPayloadDataByUserId: (state, payload) => { state.lastPayloadDataByUserId = payload; },
    setLastPayloadDataAverageBuyIn: (state, payload) => { state.lastPayloadDataAverageBuyIn = payload },
    setLastPayloadDataAdmin: (state, payload) => { state.lastPayloadDataAdmin = payload },
    setLastCancelToken(state, payload) {
        state.lastCancelToken = payload;
      },
};

const actions = {
    /**
     * Get the user's own statistics
     */
    async get({ commit, dispatch }, payload) {
        // Reset store to initial state
        commit('setData', {});
        commit('setLoading', true);
        dispatch('clearError')

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setData', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoading', false);
                return output;
            });
    },

    async getStatsStatistics({ commit, dispatch }, payload) {
        commit('setStatsStatistics', {});
        commit('setLoadingStatsStatistics', true);
        dispatch('clearError')
        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/stats/statistics/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setStatsStatistics', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingStatsStatistics', false);
                return output;
            });
    },

    async getTopProfitStatisticsByPlayer({ commit, dispatch }, payload) {
        commit('setTopProfitStatisticsByPlayer', []);
        commit('setLoadingTopProfitStatisticsByPlayer', true);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/21';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setTopProfitStatisticsByPlayer', response.data.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingTopProfitStatisticsByPlayer', false);
                return output;
            });
    },

    async getBottomProfitStatisticsByPlayer({ commit, dispatch }, payload) {
        commit('setBottomProfitStatisticsByPlayer', []);
        commit('setLoadingBottomProfitStatisticsByPlayer', true);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/22';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;
                if (output) {
                    commit('setBottomProfitStatisticsByPlayer', response.data.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingBottomProfitStatisticsByPlayer', false);
                return output;
            });
    },

    /**
     * Get a user's statistics
     */
    async getByUserId({ commit, dispatch, state }, payload) {
        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadDataByUserId, payload.params);

        // save last payload
        commit('setLastPayloadDataByUserId', payload.params);

        if (areObjectsEqual) return false;

        // Reset store to initial state
        commit('setDataByUserId', {});
        commit('setLoadingDataByUserId', true);
        dispatch('clearError');

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        return axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setDataByUserId', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingDataByUserId', false);
                return output;
            });
    },

    /**
     * Get the admin panel's statistics
     */
    async getAdmin({ commit, dispatch, state }, payload) {
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadDataAdmin, payload);
        commit('setLastPayloadDataAdmin', payload);
    
        if (areObjectsEqual) return false;
    
        commit('setDataAdmin', {});
        commit('setLoadingDataAdmin', true);
        dispatch('clearError');
    
        // Cancelar a API call se a última ainda estiver pendente
        if (state.lastCancelToken) {
            state.lastCancelToken.cancel();
        }
    
        // Criar um novo objeto CancelToken
        const cancelTokenSource = axios.CancelToken.source();
        commit('setLastCancelToken', cancelTokenSource);
    
        let output = false;

        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/admin/';

        if (payload) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);
        }

        axios.get(request_url, {
            cancelToken: cancelTokenSource.token,
          })
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setDataAdmin', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                if (!axios.isCancel(error)) {
                  commit('setError', error.response ? error.response.data.message : error);
                  commit('setHumanError', error.response ? error.response.data.human_message : error);
                  output = false;
                }
            })
            .then(function () {
                commit('setLoadingDataAdmin', false);
                return output;
            });
      },

    /**
     * Get the admin panel's statistics
     */
    async getStatisticsProfitPerPlayer({ commit, dispatch, state }, payload) {
        // Reset store to initial state
        commit('setDataProfitPerPlayer', {});
        commit('setLoadingDataProfitPerPlayer', true);
        dispatch('clearError')

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/' + payload.id;

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setDataProfitPerPlayer', response.data.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingDataProfitPerPlayer', false);
                return output;
            });

        return _.debounce(() => request, 2500);
    },

    /**
     * Get the admin panel's statistics
     */
    async getStatisticsProfitPerNetwork({ commit, dispatch, state }, payload) {
        // Reset store to initial state
        commit('setDataProfitPerNetwork', {});
        commit('setLoadingDataProfitPerNetwork', true);
        dispatch('clearError')

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/26';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setDataProfitPerNetwork', response.data.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingDataProfitPerNetwork', false);
                return output;
            });

        return _.debounce(() => request, 2500);
    },

    /**
     * Get the average buy in statistics
     */
    async getStatisticsAverageBuyIn({ commit, dispatch, state }, payload) {

        // validate payload objects before API Call
        let areObjectsEqual = DataMixin.methods.areObjectsEqual(state.lastPayloadDataAverageBuyIn, payload.params);

        if (areObjectsEqual) return false;

        // save last payload
        commit('setLastPayloadDataAverageBuyIn', payload.params);
        
        // Reset store to initial state
        commit('setDataAverageBuyIn', {});
        commit('setLoadingDataAverageBuyIn', true);
        dispatch('clearError')

        // Configure request
        let request_url = Vue.prototype.$url_api + 'v2/rails/statistics/';

        // Formulate URL with query string with generateQueryParamsUrl mixin function
        if (payload && payload.params) {
            request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);
        }

        // Execute request & return
        let output = false;

        let request = axios.get(request_url)
            .then(function (response) {
                output = response.data.success;

                if (output) {
                    commit('setDataAverageBuyIn', response.data.data);
                } else {
                    commit('setError', response.data.message);
                    commit('setHumanError', response.data.human_message);
                }
            })
            .catch(function (error) {
                commit('setError', error.response ? error.response.data.message : error);
                commit('setHumanError', error.response ? error.response.data.human_message : error);
                output = false;
            })
            .then(function () {
                commit('setLoadingDataAverageBuyIn', false);
                return output;
            });

        return _.debounce(() => request, 2500);
    },

    /**
     * Reset store to it's initial state
     */
    reset({ commit }) {
        commit('setData', {});
        commit('setDataByUserId', {});
        commit('setDataAdmin', {});
        commit('setLoading', true);
        commit('setDataProfitPerNetwork', {});
        commit('setLoadingDataProfitPerNetwork', true);
        commit('setLoadingDataByUserId', true);
        commit('setLoadingDataAdmin', true);
        commit('setError', null);
        commit('setHumanError', null);
    },

    clearError({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
