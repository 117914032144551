/* eslint-disable no-console */
import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

// Scripts
import './config'
import Store from '@/store/index';

// Components
import Login from '@/views/Login/Login.vue';
import View404 from '@/views/Error/404.vue';
import Applications from '@/views/Applications/Applications.vue';
import ApplicationsStatistics from '@/views/ApplicationsStatistics/ApplicationsStatistics.vue';
import Players from '@/views/Players/Players.vue';
import PlayersSingle from '@/views/Players-Single/Players-Single.vue';
import Groups from '@/views/Groups/Groups.vue';
import GroupsSingle from '@/views/Groups-Single/Groups-Single.vue';
import Networks from '@/views/Networks/Networks.vue';
import NetworksSingle from '@/views/Networks-Single/Networks-Single.vue';
import Classes from '@/views/Classes.vue';
import Anomalies from '@/views/Anomalies/Anomalies.vue';
import Tools from '@/views/Tools/Tools.vue';
import Nicknames from '@/views/Nicknames/Nicknames.vue';
import NicknamesSingle from '@/views/Nicknames-Single/Nicknames-Single.vue';
import Calendar from '@/views/Calendar/Calendar.vue';
import Departures from '@/views/Departures/Departures.vue';
import Contracts from '@/views/Contracts/Contracts.vue';
import VideoRequests from '@/views/VideoRequests/VideoRequest.vue';
import VideoReports from '@/views/VideoReports/VideoReports.vue';
import Schedules from '@/views/Schedules/Schedules.vue';
import SchedulesMe from '@/views/SchedulesV2/User/Schedules-User.vue';
import VideoStatistics from '@/views/VideoStatistics/VideoStatistics.vue';
import Rail from '@/views/Rail/Rail.vue';
import RailAdmin from '@/views/Rail/Admin/Rail-Admin.vue';
import Scheduling from '@/views/Scheduling/Scheduling.vue';
import SchedulingModelsAll from '@/views/Scheduling/Admin/Models-All/Models-All.vue';
import SchedulingModelSingle from '@/views/Scheduling/Admin/Models-Single/Models-Single.vue';
import SchedulingGameGroupsAll from '@/views/Scheduling/Admin/GameGroups-All/GameGroups-All.vue';
import SchedulingGameGroupsSingle from '@/views/Scheduling/Admin/GameGroups-Single/GameGroups-Single.vue';
import SchedulingGameGroupsGames from '@/views/Scheduling/Admin/GameGroups-Games/GameGroups-Games.vue';
import SchedulingGamesAll from '@/views/Scheduling/Admin/Games-All/Games-All.vue';
import SchedulingModelSingleGameGroups from '@/views/Scheduling/Admin/Models-Single-GameGroups/Models-Single-GameGroups.vue';
import SchedulingGamesSingle from '@/views/Scheduling/Admin/Games-Single/Games-Single.vue';
import SchedulingFactorsAll from '@/views/Scheduling/Admin/Factors-All/Factors-All.vue';
import SchedulingFactorsSingle from '@/views/Scheduling/Admin/Factors-Single/Factors-Single.vue';
import Dashboard from '@/views/Dashboard/Dashboard.vue';
import CalendarSingle from '@/views/CalendarSingle/CalendarSingle.vue';
import ToolsHRC from '@/views/Tools/HRC/HRC.vue';
import Teams from '@/views/Teams/Teams.vue';
import TeamsSingle from './views/Teams-Single/Teams-Single.vue';
import Audit from '@/views/Audit/Audit.vue';
import AuditSingle from '@/views/AuditSingle/AuditSingle.vue';
import StatsAdmin from './views/Stats-Admin/Stats-Admin.vue';
import LevelChange from '@/views/LevelChange/LevelChange.vue';
import Games from '@/views/Games/Games.vue';
import GameEntries from './views/GameEntries/GameEntries.vue';
import GameSingle from './views/Games-Single/Games-Single.vue';
import LiveGame from './views/LiveGame/LiveGame.vue';
import LiveNotes from '@/views/LiveNotes/LiveNotes.vue';
import LiveSearch from '@/views/LiveSearch/LiveSearch.vue';
import LiveEvents from '@/views/LiveEvents/LiveEvents.vue';
import LiveTournaments from '@/views/LiveTournaments/LiveTournaments.vue';
import LivePlayers from '@/views/LivePlayers/LivePlayers.vue';
import LivePlayersSingle from '@/views/LivePlayers-Single/LivePlayers-Single.vue';
import LiveTeams from '@/views/LiveTeams/LiveTeams.vue';
import LiveEventsSingle from '@/views/LiveEvents-Single/LiveEvents-Single.vue';
import TournamentsSingle from '@/views/Tournaments-Single/Tournaments-Single.vue';
import Profit from '@/views/Profit/Profit.vue';

const routes = [
    { name: 'Início', path: '/', component: Dashboard },
    { name: 'Tracker', path: '*', component: View404 },
    { name: 'Iniciar sessão', path: '/login', component: Login },
    { name: 'Jogadores', path: '/players', component: Players },
    { name: 'Jogador #', path: '/players/:id', component: PlayersSingle },
    { name: 'Níveis', path: '/groups', component: Groups },
    { name: 'Nível', path: '/groups/:id', component: GroupsSingle },
    { name: 'Salas', path: '/networks', component: Networks },
    { name: 'Sala', path: '/networks/:id', component: NetworksSingle },
    { name: 'Nicknames', path: '/nicknames', component: Nicknames },
    { name: 'Nickname', path: '/nicknames/:id', component: NicknamesSingle },
    { name: 'Candidaturas', path: '/applications', component: Applications },
    { name: 'Saídas', path: '/departures', component: Departures },
    { name: 'Contratos', path: '/contracts', component: Contracts },
    { name: 'Pedidos de vídeo', path: '/videos/requests', component: VideoRequests },
    { name: 'Relatórios de vídeo', path: '/videos/reports', component: VideoReports },
    { name: 'Anomalias', path: '/anomalies/', component: Anomalies },
    { name: 'Anomalias', path: '/anomalies/report/:iteration', component: Anomalies },
    { name: 'Anomalias', path: '/anomalies/date/:date', component: Anomalies },
    { name: 'Schedules', path: '/schedules/', component: Schedules },
    { name: 'Schedules', path: '/schedules/me/', component: SchedulesMe },
    { name: 'Visão Geral', path: '/rail/', component: Rail },
    { name: 'Rail', path: '/rail/', component: Rail },
    { name: 'Rail - Administração', path: '/rail/admin/', component: RailAdmin },
    { name: 'Operações', path: '/rail/operations/', component: Rail },
    { name: 'Operação #', path: '/rail/operations/:id', component: Rail },
    { name: 'Sessões', path: '/rail/sessions/', component: Rail },
    { name: 'Sessão', path: '/rail/sessions/:id', component: Rail },
    { name: 'Transferências', path: '/rail/transfers/', component: Rail },
    { name: 'Transferência', path: '/rail/transfers/:id', component: Rail },
    { name: 'Swaps', path: '/rail/swaps/', component: Rail },
    { name: 'Swap', path: '/rail/swaps/:id', component: Rail },
    { name: 'Grupo de swap', path: '/rail/swaps/groups/:id', component: Rail },
    { name: 'Reports', path: '/rail/reports/', component: Rail },
    { name: 'Report', path: '/rail/reports/:id', component: Rail },
    { name: 'Despesas', path: '/rail/expenses/', component: Rail },
    { name: 'Despesa', path: '/rail/expenses/:id', component: Rail },
    { name: 'Estatística', path: '/rail/statistics/', component: Rail },
    { name: 'Ação', path: '/rail/action/', component: Rail },
    { name: 'Estatísticas de candidatura', path: '/stats/applications', component: ApplicationsStatistics },
    { name: 'Estatísticas de vídeo', path: '/stats/videos', component: VideoStatistics },
    { name: 'Scheduling', path: '/scheduling/', component: Scheduling },
    { name: 'Scheduling - Modelos', path: '/scheduling/models/', component: SchedulingModelsAll },
    { name: 'Scheduling - Modelo', path: '/scheduling/models/:id', component: SchedulingModelSingle },
    { name: 'Scheduling - Game Groups', path: '/scheduling/gameGroups', component: SchedulingGameGroupsAll },
    { name: 'Scheduling - Game Group', path: '/scheduling/gameGroups/:id', component: SchedulingGameGroupsSingle },
    { name: 'Scheduling - Games from game group', path: '/scheduling/gameGroups/:id/games', component: SchedulingGameGroupsGames },
    { name: 'Scheduling - Games', path: '/scheduling/games', component: SchedulingGamesAll },
    { name: 'Scheduling - Game Groups from model', path: '/scheduling/models/:id/gamegroups', component: SchedulingModelSingleGameGroups },
    { name: 'Scheduling - Game', path: '/scheduling/games/:id/', component: SchedulingGamesSingle },
    { name: 'Scheduling - Fatores', path: '/scheduling/factors', component: SchedulingFactorsAll },
    { name: 'Scheduling - Fator', path: '/scheduling/factors/:id', component: SchedulingFactorsSingle },

    { name: 'Classes', path: '/classes/', component: Classes },
    { name: 'Tools', path: '/tools/', component: Tools },
    { name: 'HRC', path: '/tools/hrc/', component: ToolsHRC },
    { name: 'Calendário', path: '/calendar/', component: Calendar },
    { name: 'Calendário - Evento', path: '/calendar/event/:id', component: CalendarSingle},

    { name: 'Equipas', path: '/teams', component: Teams },
    { name: 'Equipa', path: '/teams/:id', component: TeamsSingle },

    { name: 'Rail do Jogador', path: '/players/:id/rail/', component: PlayersSingle },
    { name: 'Desempenho do Jogador', path: '/players/:id/performance/', component: PlayersSingle },
    { name: 'Nível do Jogador', path: '/players/:id/level/', component: PlayersSingle },
    { name: 'Stats do Jogador', path: '/players/:id/stats/', component: PlayersSingle },
    { name: 'Torneios do Jogador', path: '/players/:id/games/', component: PlayersSingle },
    { name: 'Ficheiros do Jogador', path: '/players/:id/files/', component: PlayersSingle },

    { name: 'Ficheiros', path: '/tools/pokercraft/', component: Audit },
    { name: 'Ficheiro #', path: '/tools/pokercraft/:id', component: AuditSingle },

    { name: 'Stats', path: '/stats', component: StatsAdmin },
    { name: 'Progressão de nível', path: '/levelchange/', component: LevelChange },
    { name: 'Alteração de nível', path: '/levelchange/:id', component: LevelChange },
    { name: 'Evolução do Jogador', path: '/players/:id/level/', component: PlayersSingle },
    { name: 'Jogos', path: '/games/', component: Games },
    { name: 'Entradas de Jogos ', path: '/gameEntries/', component: GameEntries },
    { name: 'Jogo', path: '/games/:id', component: GameSingle },

    
    { name: 'Live', path: '/live/', component: LiveSearch },
    { name: 'Modo jogo live', path: '/live/now/', component: LiveGame },
    { name: 'Eventos', path: '/live/events/', component: LiveEvents },
    { name: 'Torneios', path: '/live/tournaments/', component: LiveTournaments },
    { name: 'Jogadores', path: '/live/players/', component: LivePlayers },
    { name: 'Notas', path: '/live/notes/', component: LiveNotes },
    { name: 'Equipas', path: '/live/teams/', component: LiveTeams },

    { name: 'Evento', path: '/live/events/:id', component: LiveEventsSingle },
    { name: 'Torneio', path: '/live/events/:id/tournaments/:id', component: TournamentsSingle },
    { name: 'Torneio', path: '/live/tournaments/:id', component: TournamentsSingle },
    { name: 'Jogador', path: '/live/players/:id', component: LivePlayersSingle },
    { name: 'Equipa', path: '/live/teams/:id', component: LiveTeams },

    { name: 'Lucro dos jogadores', path: '/profit', component: Profit },

];

const router = new VueRouter({
    routes,
    mode: 'history',
    base: Vue.prototype.publicPath,
});

// Navigation guard (https://router.vuejs.org/guide/advanced/navigation-guards.html#global-before-guards)
router.beforeEach((to, from, next) => {
    AuthGuard(to, from, next);
});

async function AuthGuard(to, from, next) {
    // Check if user is already authenticated
    if (Store.state.auth.userAuthenticated) {
        return next();
    }

    // Authenticate user through token
    // Not yet authenticated, go through procedure
    let authentication = false;
    authentication = await Store.dispatch('auth/decodeToken');

    // Verify user authentication or redirect him to login
    if (authentication) {
        //console.log('User is authenticated.');

        if (to.path != '/login')
            return next();
        else
            return next({ path: '/' });
    } else {
        //console.log('User is not authenticated. Redirecting to login page.');

        if (to.path != '/login') {
            return next({ path: '/login' }); // User not authenticated, redirect to login page
        } else {
            return next(); // User was already redirected to login (prevents infinite loop)
        }
    }
} 

export default router;
