import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import UserMixin from '@/mixins/User.vue';
import RailMixin from '@/mixins/Rail.vue';
import DatePickerRange from '@/components/DatePickerRange/DatePickerRange.vue';
import UserGroupProgress from '@/components/UserGroupProgress/UserGroupProgress.vue';
import RailDivisionDropdown from '@/components/Rail/RailDivisionDropdown/RailDivisionDropdown.vue';
import CurrencyRates from '@/components/Rail/CurrencyRates/CurrencyRates.vue';
import PlayerGroupBadge from '@/components/PlayerGroupBadge/PlayerGroupBadge.vue';
import Breadcrumb from '@/components/Hero/Breadcrumb/Breadcrumb.vue';
import RailDocumentsDropdown from '@/components/Rail/RailDocumentsDropdown/RailDocumentsDropdown.vue';

export default {
	name: 'Hero',

	mixins: [
		DataMixin,
		UserMixin,
		AuthMixin,
		RailMixin,
	],

	components: {
		DatePickerRange,
		UserGroupProgress,
		Breadcrumb,
		RailDivisionDropdown,
		CurrencyRates,
		PlayerGroupBadge,
		RailDocumentsDropdown,
    },

	props: {
		title: {
			type: String,
			required: true,
			default: 'Tracker',
		},
		userStatus: {
			type: Number,
			required: false,
			default: null,
		},
		navigationMonth: {
			type: Boolean,
			required: false,
			default: false,
		},
		message: {
			type: String,
			required: true,
			default: 'Portal de gestão do jogador.',
		},
		icon: {
			type: String,
			required: false,
			default: '',
		},
		help_url: {
			type: String,
			required: false,
		},
		// Default values e.g 'withAction', withoutAction, min-height-auto
		action: {
			type: String,
			required: false,
			default: 'min-height-auto',
		},
		showRates: {
			type: Boolean,
			required: false,
			default: false,
		},
		calendar: {
			type: Boolean,
			required: false,
			default: false,
		},
		// Default calendar dates e.g. [ "2021-05-09", "2021-05-14" ]
		calendarDates: {
			type: Array,
			required: false,
			default: () => [],
		},
		image_path: {
			type: String,
			required: false,
			default: '888_usd'
		},
		imagePath: {
			type: String,
			required: false,
			default: '',
		  },
		image_name: {
			type: String,
			required: false,
			default: '888 Poker'
		},
		status: {
			type: Number,
			required: false,
			default: 1
		},
		// Default type e.g. 'nickname', 'network', 'group', 'user', 'team', 'video', 'rails'
		layout_type: {
			type: String,
			required: false,
			default: ''
		},
		userID: {
			type: Number,
			required: false,
			default: null
		},
		searchOnlyTypeTeam:{
			type: Number,
			required: false,
			default: null,
		},
		showParamsUrl: {
			type: Boolean,
			required: false,
			default: true,
		},
		liveImageEvent: {
			type: String,
			required: false,
			default: '',
		}
	},

	computed: {
		...mapGetters({
			heroDatePickerValue: 'UI/heroDatePickerValue',
			selectedUser: 'users/selectedUser',
			userImgCutout: 'auth/userImgCutout',
		}),
		
		breadcrumbItems() {
			return this.generateBreadcrumbPaths();
		},
		
		computedCalendarDates: {
			get: function () {
				return this.calendarDatesFinal;
			},
			set: function (newValue) {
				this.calendarDatesFinal = newValue;
				this.$store.commit('UI/setHeroDatePickerValue', newValue);
			}
		},
		actionClass: function () {
			return this.action;
		},

		resolvedImagePath() {
			let images = ['spins.png', 'mtt.png', 'cash.png'];
			if (images.includes(this.imagePath)) {
			  return require('@/assets/teams/' + this.imagePath);
			} else {
			  return require('@/assets/teams/noteam.png');
			}
		  },

		getPlayerLink: function () {
			return this.userID
				? '/players/' + this.userID
				: '/#';
		},

		isVisible: function () {
			let layout = ['Rail - Administração', 'Rail', 'Jogadores', 'Nicknames', 'Níveis', 'Lucro geral dos jogadores'];

			return layout.includes(this.title) && this.userHasRailAccessDivisionDropdown;
		},

		includedSpecificTeams: function () {
			if (Vue.prototype.$teams_ids) {
				return [
					Vue.prototype.$teams_ids.division_mtt,
					Vue.prototype.$teams_ids.division_cash,
					Vue.prototype.$teams_ids.division_spins,
				];
			}
		},
		
	},

	watch: {
		calendarDates: function (newValue) {
			this.computedCalendarDates = newValue;
		},
		heroDatePickerValue: function (newValue) {
			this.computedCalendarDates = newValue;
		},
	},

	data() {
		return this.initialState();
	},

	created() {
		// Save data passed to this component via v-model
		this.calendarDatesFinal = this.calendarDates;
	},

	methods: {
		initialState() {
			return {
				calendarDatesFinal: [],
				layout: {
					nickname: 'nickname',
					network: 'network',
					user: 'user',
					video: 'video',
					rails: 'rails',
					group: 'group',
					team: 'team',
					anomalies: 'anomalies',
				},
				paths: [{ text: 'Home', to: '/' }]
			};
		},

		isValidImagePath(imagePath) {
			try {
			  require('@/assets/' + imagePath);
			  return true;
			} catch (error) {
			  return false;
			}
		  },

		generateBreadcrumbPaths() {
			const allRoutes = this.$router.options.routes
			

			const routeSegments = this.$route.path.split('/').filter(segment => segment !== '');
			const routeMatchedPath = this.$route.matched[0].path;

			let currentPath = '';

			const thisRouteHasParams = routeMatchedPath.includes(':')
			
			for (let i = 0; i < routeSegments.length; i++) {
				currentPath += `/${routeSegments[i]}`;

				// if the path is /events/id/tournaments/id, we don't want to show the tournaments path (we don't have a route for it)
				if (currentPath.includes('/events/') && currentPath.endsWith('/tournaments')) {
					continue;
				}

				let text = allRoutes.find((route) => {
					let routePath = route.path;
					const routeEndsWithSlash = route.path.length > 2 && route.path.endsWith('/');

					if(routeEndsWithSlash) {
						routePath = routePath.slice(0, -1);
					}

					const routeHasParam = routePath.includes(':');				

					if (thisRouteHasParams && routeHasParam && this.showParamsUrl) {
						const [params] = Object.keys(this.$route.params)
						
						let newPath = `${routePath}` 
						
						if (routePath.includes(`:${params}`)) {
							newPath = newPath.replace(`:${params}`, this.$route.params[params])
						}

						return newPath === currentPath	
					}
					
					return routePath === currentPath
				});

				if (!text) {
					text = allRoutes.find((route) => {
						return route.path === this.$route.matched[0].path;
					})
				}
				const dateRegex = /\d{4}-\d{2}-\d{2}/; 
				if(!currentPath.endsWith('/date') && !(currentPath === '/videos') && !dateRegex.test(currentPath) && !(currentPath.endsWith('me'))){
					let pathItem = {
						text: text.name,
						to: currentPath
					};

					//If currentPath is "live/events/uuid", we will set the route name to 'event'. This is an exception case.
					const uuidRegexForEventsView = /live\/events\/[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
					if (uuidRegexForEventsView.test(currentPath)) {
						pathItem.text = 'Evento';
					}

					this.paths.push(pathItem);
				}
			}

			const finalPaths = [];
			const seenPaths = {};

			for (let obj of this.paths) {
				const path = obj.to;

				if (!seenPaths[path] && !path.includes('?')) {
				  seenPaths[path] = true;
				  finalPaths.push(obj);
				}
			}

			this.paths = finalPaths;

			if(Object.keys(this.$route.query).length > 0){
				const params = new URLSearchParams(this.$route.query).toString();
				this.paths[this.paths.length-1].to += `?${params}`
			}

			this.paths = this.removeBranches(this.paths, currentPath);

			this.paths = this.paths.map(element => {
				if (element.text == 'Ficheiro #' || element.text == 'Operação #' || element.text == 'Evento #' || element.text == 'Torneio #' || element.text == 'Equipa #' || element.text == 'Jogador #') {
					return {
						text: element.text + '' + this.$route.params.id,
						to: element.to
					}
				} else {
					return element;
				}
			})

			return this.paths;
		  },
		  
		removeBranches(paths, currentPath) {
			let newPaths = paths.filter(path => currentPath.includes(path.to) || path.to.includes('?'));
			return newPaths
		},

		imageCutout() {
            let placeholder = require('@/assets/images/players/player-example.png');
            return this.selectedUser && this.selectedUser.imgCutout ? this.selectedUser.imgCutout : placeholder;
        },
	},
};
