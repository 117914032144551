/**
* @prop    {Array[Int]}    excludedNetworks        Hide given networks.
* @prop    {Array[Int]}    filterNetworks          Filter given networks.
* @prop    {Array[Int]}    currencies              Show ONLY networks with these currencies.
*/

import { mapGetters } from 'vuex';

export default {
    name: 'NetworkDropdown',

    props: {
        value: {
            type: Object,
            required: false,
            default: () => {}
        },
        label: {
            type: String,
            required: false,
            default: 'Sala'
        },
        excludedNetworks: {
            type: Array,
            required: false,
            default: () => []
        },
        filterNetworks: {
            type: Array,
            required: false,
            default: () => []
        },
        currencies: {
            type: Array,
            required: false,
            default: () => []
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },
        solo: {
            type: Boolean,
            required: false,
            default: false
        },
        outlined: {
            type: Boolean,
            required: false,
            default: true
        },
        rules: {
            type: Array,
            required: false,
            default: () => [
                (v) => !!v || 'Por favor preencha a sala',
            ]
        },
        hideDetails: {
            type: Boolean,
            required: false,
            default: false
        },
        clearable: {
            type: Boolean,
            required: false,
            default: false
        },
        dense: {
            type: Boolean,
            required: false,
            default: false
        }
    },

    data() {
        return this.initialState();
    },

    created() {
        this.initialize();
        this.emitChange();
    },

    mounted() {
        // Clear error triggered naturally when element is loaded
        this.$refs.formNetworkDropdown.resetValidation();
    },
    
    computed: {
        ...mapGetters({
            networks: 'networks/networks',
            isLoading: 'networks/loading',
        }),

        /**
         * Filters data partially, excluding repeated networks as user adds.
         * WARNING: The data is previously filtered according to other props in the 'fetchData()' method.
         * @returns {Array}
         */
        dataFiltered: function () {
            let output = this.data;
            // (If set) Show only nicknames of the given currencies
            output = this.filterByCurrency(this.data);
            // (If set) Show only networks of the given prop filterNetworks type
            output = !this._.isEmpty(this.filterNetworks) ? this.filterSpecificNetworks(output) : output ;
            // (If set) Dont show the given networks (usually to avoid duplication)
            output = this.excludeRepeatedNetworks(output);
            return output;
        },
    
        // Synthetic v-model
        computedValue: {
            get: function () {
                return this.network;
            },
            set: function (newValue) {
                this.network = newValue;
            }
        },

        // return prop rules
        computedRules: function () {
            return this.rules;
        },
    },

    watch: {
        value: function(newValue) {
            this.computedValue = newValue;
        },
        /**
        *  runs when component is created and everytime currencies has changed
        */
        currencies: function () {
            this.data = this.networks;
            this.data = this.filterByCurrency(this.data);
           
            return this.data; 
        },
    },

    methods: {
        initialState() {
            return {
                network: null,
                data: [],
            }
        },

        initialize() {
            // Initial value passed through prop
            this.computedValue = this.value;
            this.fetchData();
        },

        async fetchData() {
            // get networks list
            this.$store.commit('networks/setPromiseNetworks', null);
            if (this._.isEmpty(this.networks) || this.networks.length <= 10) {
                await this.$store.dispatch('networks/get');
            }
            this.data = this.networks;
            return this.data;
        },

        /**
         * Exclude certain networks from the results.
         * 
         * @param {*} data 
         * @returns {Array}
         */
        excludeRepeatedNetworks(data) {
            return data.filter(e => ! this.excludedNetworks.includes(e.id) );
        },

        /**
         * Filter specific networks from the results.
         * 
         * @param {*} data 
         * @returns {Array}
         */
        filterSpecificNetworks(data) {
            return data.filter(e => this.filterNetworks.includes(e.id) );
        },

        /**
         * Exclude networks that don't have a certain currency.
         * 
         * @param {*} data 
         * @returns {Array}
         */
        filterByCurrency(data) {
            if (! this._.isEmpty(this.currencies)) {
                data = data.filter(e => this.currencies.includes(e.currency.id));
            }
            return data;
        },

        reset() {
            Object.assign(this.$data, this.initialState());
            this.$refs.formNetworkDropdown.reset();
        },

        resetNetwork() {
            this.network = null;
            this.$refs.formNetworkDropdown.reset();
        },

        emitChange() {
            this.$emit('input', this.computedValue);
            this.$emit('change', this.computedValue);
        },
    }
}