import Vue from 'vue';
import { mapGetters } from 'vuex';
import UserMixin from '@/mixins/User.vue';
import AuthMixin from '@/mixins/Auth.vue';
import DataMixin from '@/mixins/Data.vue';
import Snackbar from '@/components/Snackbar';
import Hero from '@/components/Hero/Hero.vue';
import Loading from '@/views/Loading.vue';
import DialogAddEditPlayerLive from '@/components/Dialog/DialogAddEditPlayerLive/DialogAddEditPlayerLive.vue';
import DialogAddNicknamesToPlayerLive from '@/components/Dialog/DialogAddNicknamesToPlayerLive/DialogAddNicknamesToPlayerLive.vue';
import Breadcrumb from '@/components/Hero/Breadcrumb/Breadcrumb.vue';
import DialogAddEditNotes from '@/components/Dialog/DialogAddEditNotes/DialogAddEditNotes.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import DialogConfirmation from '@/components/Rail/Dialog/DialogConfirmation/DialogConfirmation.vue';
import TableNotes from "@/components/Tables/TableNotes/TableNotes.vue";

export default {
    name: 'LivePlayers-Single',

    components: {
        Loading,
        Snackbar,
        Hero,
        Breadcrumb,
        DialogAddEditPlayerLive,
        DialogAddNicknamesToPlayerLive,
        DialogAddEditNotes,
        EmptyStateNoData,
        DialogConfirmation,
        TableNotes,
    },

    mixins: [
        UserMixin,
        AuthMixin,
        DataMixin,
    ],

    data() {
        return this.initialState();
    },

    async created() {
        // Load necessary page data
        if (this.$route.params.id) {
            await this.fetchData(this.$route.params.id);
        }

        this.populateFields();
    },

    beforeDestroy() {
        this.$store.commit('livePlayers/setSelectedPlayerNotes', []);
        this.$store.commit('livePlayers/setSelectedPlayerTags', []);
        this.$store.commit('livePlayers/setSelectedPlayerNicknames', []);
    },

    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
            selectedPlayerLoading: 'livePlayers/selectedPlayerLoading',
            selectedPlayerTags: 'livePlayers/selectedPlayerTags',
            selectedPlayerTagsLoading: 'livePlayers/selectedPlayerTagsLoading',
            selectedPlayerNicknames: 'livePlayers/selectedPlayerNicknames',
            countries: 'countries/countries',
            selectedPlayerNotes: 'livePlayers/selectedPlayerNotes',
            selectedPlayerNotesLoading: 'livePlayers/selectedPlayerNotesLoading',
            selectedNote: 'notes/selectedNote',
        }),

        player: function () {
            return this.selectedPlayer;
        },

        filteredPlayerNotes() {
            if(this.selectedPlayerNotes && this.selectedPlayerNotes.length > 0){
                return this.selectedPlayerNotes.filter(note => {
                    return note.text.toLowerCase().includes(this.search.toLowerCase()) || note.title.toLowerCase().includes(this.search.toLowerCase());
                });
            }

            return this.selectedPlayerNotes;
        },

        tagsComputed: function () {
            let tags = [
                'reg over agro',
                'pato over agro',
                'nit',
                'whale',
                'reg live / reg fraco',
                'pato high-stakes',
                'reg high-stakes'
            ];
        
            return tags;
        },

        // Synthetic v-model
        tagValueComputed: {
            get: function () {
                return this.tags;
            },
            set: function (newValue) {
                    this.tags = newValue;
            }
        },

    },

    watch: {
        heroDatePickerValue: function () {
        },
        '$route.params.id': function () {
            this.populateFields();
        },
        selectedPlayerTags: function () {

        },
    },

    methods: {
        initialState() {
            return {
                tags: [],
                tagList: [
                    { name: 'reg over agro', color: '', description: '' },
                    { name: 'pato over agro', color: '', description: '' },
                    { name: 'nit', color: '', description: '' },
                    { name: 'whale', color: '', description: '' },
                    { name: 'reg live / reg fraco', color: '', description: '' },
                    { name: 'pato high-stakes', color: '', description: '' },
                    { name: 'reg high-stakes', color: '', description: '' }
                ],
                submitting: false,
                submittingDelete: false,
                search: "",
                maxTagsToShow: 5,
                tab: {
                    current: 0,
                    // URLs need to match router.js
                    items: [
                        { name: 'Notas', slug: '', disabled: false },
                        { name: 'Nicknames', slug: 'rail', disabled: false },
                        { name: 'Entradas', slug: 'performance', disabled: true },
                    ],
                },
                dialogues: {
                    access: {
                        confirmation: false,
                        feedback: false
                    }
                },
                submittingRemoveAccess: false,
                dialogues: {
                    addEditPlayerLive: false,
                    addEditNote: false,
                    addNicknamesToPlayer: false,
                    deleteEditNote: false,
                },
                action: 'update',
                actionNote: 'create',
                addTagMenu: false,
                loadingNotes: false,
            };
        },

        async loadingNotesFromPlayer(){
            this.loadingNotes = true;
            setTimeout(() => {
                if(this.selectedPlayer && this.selectedPlayer.id){
                    this.$store.dispatch('livePlayers/fetchSelectedPlayerNotes', { params: { id: this.selectedPlayer.id } });
                }
                this.loadingNotes = false;
            }, 1300);  
        },

        populateFields() {
            this.tagValueComputed = this.tagsWithOnlyNames();
            // this.loadingNotesFromPlayer();
        },

        handleUsername(user) {
            let output = '';
        
            if (user && user.name) {
                // Divide o nome em palavras
                const names = user.name.split(' ');
                
                // Pega a primeira letra dos dois primeiros nomes
                if (names.length > 1) {
                    output = names[0][0] + names[1][0];
                } else if (names.length === 1) {
                    output = names[0][0];
                }
            }
        
            return output.toUpperCase(); // Converte para maiúsculas
        },
        

        toggleDialog(dialog, note=null) {
            this.dialogues[dialog] = !this.dialogues[dialog];
        },

        // set active note
        setActiveItem(item) {
            if (item) this.$store.commit('notes/setSelectedNote', item);
        },

        editPlayer(player) {
            this.$store.commit('livePlayers/setSelectedPlayer', player);
            this.action = 'update';
            this.toggleDialog('addEditPlayerLive');
        },

        editNotePlayer(note){
            this.actionNote = 'update';
            this.setActiveItem(note);
            this.toggleDialog('addEditNote');
        },

        deleteNotePlayer(note) {
            this.setActiveItem(note);
            this.toggleDialog('deleteEditNote');
        },

        async submitDeleteNote() {
            this.submittingDelete = true;
            let result = await this.$store.dispatch('notes/delete', { id: this.selectedNote.id });
            this.submittingDelete = false;
            if (result) {
                this.loadingNotesFromPlayer();
                this.toggleDialog('deleteEditNote');
            }
        },

        async addTagsToPlayer(player) {
            let payload = player;
            payload.tags = this.tagsWithDetails(this.tagValueComputed);

            this.submitting = true;
            let result = await this.$store.dispatch('livePlayers/addPlayerTag', payload);
            this.submitting = false;
            
            if (result === true) {
                this.addTagMenu = false;
                this.tagValueComputed = this.tagsWithOnlyNames();
            }
        },

        async fetchData(id) {
            await this.$store.dispatch('livePlayers/fetchSelectedPlayer', id);
        },

        getCodeFromCountry(name) {
            let output = 'unknown.svg';

            if (!this._.isEmpty(this.countries) && name) {
                let countryObject = this.countries.find(e => e.name == name);
                if (countryObject) {
                    output = countryObject.code + '.svg'
                }
            }

            return output;
        },

        // used to prepare payload
        tagsWithDetails(tagNames) {
            return tagNames.map(tagName => {
                return this.tagList.find(tag => tag.name === tagName);
              }).filter(tag => tag !== undefined);
        },

        tagsWithOnlyNames() {
            return this.selectedPlayerTags.map(element => element.name);
        },
    },
}
