<script>
export default {
    methods: {
        /**
         * Formulate URL with query string
         * @param {String} - url - initial url without URL parameters
         * @param {Object} - params - parameters to be filtered in URL 
         * @returns String - final url
         */
        generateQueryParamsUrl(url,params) {
            let request_url = url;
            if (params) {
                request_url = new URL(request_url);
                for (let key in params) {
                    request_url.searchParams.append(key, params[key]);
                }
                request_url = request_url.toString();
            }
            return request_url;
        },

        /**
         * Default parameters used in stores
         * Disables pagination by default (itemsPerPage -1 = retrieve all)
         * @returns {Object} Object used to formulate URL
         */
        getParametersDefault() {
            return {
                page: 1,
                itemsPerPage: -1
            }
        },

        /**
         * @param {String|undefined} - param - ongoing parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleRailRequestsMutations(param = undefined) {
            let output = {
                requests: '',
                total: '',
                loading: ''
            };

            if (Object.hasOwnProperty.call(param, 'ongoing')) {
                param = param.ongoing;
            }

            switch (parseInt(param)) {
                case 0:
                    output = {
                       requests: 'setFinalizedRequests',
                       total: 'setTotalFinalizedRequests',
                       loading: 'setHasLoadedFinalizedRequests'
                    };
                break;
                case 1:
                    output = {
                       requests: 'setOngoingRequests',
                       total: 'setTotalOngoingRequests',
                       loading: 'setHasLoadedOngoingRequests'
                    };
                break;
                default:
                    output = {
                       requests: 'setRequests',
                       total: 'setTotalRequests',
                       loading: 'setHasLoaded'
                    };
                break;
            }

            return output;
        },

        /**
         * @param {String|undefined} - param - ongoing parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleUserMutations(param = undefined ) {
            let output = {
                users: '',
                total: '',
                loading: ''
            };
            switch (param) {
                case 1:
                    output = {
                       users: 'setUsersInactive',
                       total: 'setUsersInactiveTotal',
                       loading: 'setUsersInactiveLoading'
                    };
                    break;
                case 2:
                    output = {
                       users: 'setUsersActive',
                       total: 'setUsersActiveTotal',
                       loading: 'setUsersActiveLoading'
                    };
                    break;
                case 3:
                    output = {
                       users: 'setUsersOut',
                       total: 'setUsersOutTotal',
                       loading: 'setUsersOutLoading'
                    };
                    break;
                default:
                    output = {
                       users: 'set',
                       total: 'setUsersTotal',
                       loading: 'setLoading'
                    };
                break;
            }
            return output;
        },
        
        /**
         * @param {String|undefined} - param - tab parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleNicknameMutations(param = undefined ) {
            let output = {
                nicknames: '',
                total: '',
                loading: ''
            };
            switch (param) {
                case 1:
                    output = {
                       nicknames: 'setNicknamesActive',
                       total: 'setNicknamesActiveTotal',
                       loading: 'setHasLoadedNicknamesActive'
                    };
                    break;
                case 2:
                    output = {
                       nicknames: 'setNicknamesOut',
                       total: 'setNicknamesOutTotal',
                       loading: 'setHasLoadedNicknamesOut'
                    };
                    break;
                case 3:
                    output = {
                       nicknames: 'setNicknamesInactive',
                       total: 'setNicknamesInactiveTotal',
                       loading: 'setHasLoadedNicknamesInactive'
                    };
                    break;
                default:
                    output = {
                       nicknames: 'setNicknames',
                       total: 'setNicknamesTotal',
                       loading: 'setHasLoaded'
                    };
                break;
            }
            return output;
        },

        /**
         * @param {String|undefined} - param - status parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleReportMutations(param = undefined ) {
            let output = {
                reports: '',
                total: '',
                loading: ''
            };
            switch (param) {
                case 1:
                case 2:
                    output = {
                       reports: 'setReportsPending',
                       total: 'setReportsPendingTotal',
                       loading: 'setReportsPendingLoading'
                    };
                    break;
                case 3:
                    output = {
                       reports: 'setReportsFinalized',
                       total: 'setReportsFinalizedTotal',
                       loading: 'setReportsFinalizedLoading'
                    };
                    break;
                default:
                    output = {
                       reports: 'set',
                       total: 'setTotalReports',
                       loading: 'setLoading'
                    };
                break;
            }
            return output;
        },

        /**
         * @param {String|undefined} - param - ongoing parameter
         * @param {String|undefined} - pending - pending parameter
         * @param {Number|undefined} - status - status parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleRailTransfersMutations(param = undefined, pending = undefined, status = undefined) {
            let output = {
                transfers: '',
                total: '',
                loading: ''
            };
            switch (param) {
                case 0:
                    output = {
                       transfers: 'setPlayerTransfers',
                       total: 'setPlayerTransfersTotal',
                       loading: 'setPlayerTransfersLoading'
                    };
                break;
                case 1:
                    output = {
                       transfers: 'setManagementTransfers',
                       total: 'setManagementTransfersTotal',
                       loading: 'setManagementTransfersLoading'
                    };
                break;
                default:
                    output = {
                       transfers: 'set',
                       total: 'setTotalTransfers',
                       loading: 'setLoading'
                    };
                break;
            }

            if (pending) {
                output = {
                    transfers: 'setPendingTransfers',
                    total: 'setPendingTransfersTotal',
                    loading: 'setPendingTransfersLoading'
                }
            }

            if (status) {
                output = {
                    transfers: 'setRejectedTransfers',
                    total: 'setRejectedTransfersTotal',
                    loading: 'setRejectedTransfersLoading'
                }
            }

            return output;
        },

        /**
         * @param {String|undefined} - param - ongoing parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleRailSwapsMutations(param = undefined) {
            let output = {
                swaps: '',
                total: '',
                loading: ''
            };
            switch (param) {
                case 1:
                    output = {
                       swaps: 'setPendingSwaps',
                       total: 'setPendingSwapsTotal',
                       loading: 'setPendingSwapsLoading'
                    };
                break;
                case 4:
                    output = {
                       swaps: 'setRejectedSwaps',
                       total: 'setRejectedSwapsTotal',
                       loading: 'setRejectedSwapsLoading'
                    };
                break;
                case 5:
                    output = {
                       swaps: 'setFinalizedSwaps',
                       total: 'setFinalizedSwapsTotal',
                       loading: 'setFinalizedSwapsLoading'
                    };
                break;
                default:
                    output = {
                       swaps: 'set',
                       total: 'setSwapsTotal',
                       loading: 'setLoading'
                    };
                break;
            }
            return output;
        },


        /**
         * @param {String|undefined} - param - status parameter
         * @returns {Object} Object used to know which mutations will run to populate states
         */
        handleGroupsChangesMutations(param = undefined ) {
            let output = {
                items: '',
                total: '',
                loading: ''
            };
            
            switch (param) {
                case 1:
                    output = {
                       items: 'setPendingGroupsChanges',
                       total: 'setPendingGroupsChangesTotal',
                       loading: 'setPendingGroupsChangesLoading'
                    };
                    break;
                case 2:
                case 3:
                    output = {
                       items: 'setFinalizedGroupsChanges',
                       total: 'setFinalizedGroupsChangesTotal',
                       loading: 'setFinalizedGroupsChangesLoading'
                    };
                    break;
                default:
                    output = {
                       items: 'setGroupsChanges',
                       total: 'setGroupsChangesTotal',
                       loading: 'setGroupsChangesLoading'
                    };
                break;
            }
            return output;
        },

        handleAuditMutations(param = undefined ) {
            let output = {
                items: '',
                total: '',
                loading: ''
            };
            
            switch (param) {
                case 1:
                case 2:
                    output = {
                       items: 'setAuditsPending',
                       total: 'setAuditsPendingTotal',
                       loading: 'setAuditsPendingLoading'
                    };
                    break;
                case 3:
                case 4:
                    output = {
                       items: 'setAuditsFinalized',
                       total: 'setAuditsFinalizedTotal',
                       loading: 'setAuditsFinalizedLoading'
                    };
                    break;
                default:
                    output = {
                       items: 'setAudits',
                       total: 'setAuditsTotal',
                       loading: 'setAuditsLoading'
                    };
                break;
            }
            return output;
        }
    }
}
</script>
