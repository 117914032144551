import Vue from 'vue';
import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import Overview from '@/components/TabSlider/Tabs/Overview/Overview.vue';
import Operations from '@/components/TabSlider/Tabs/Operations/Operations.vue';
import Sessions from '@/components/TabSlider/Tabs/Sessions/Sessions.vue';
import Transfers from '@/components/TabSlider/Tabs/Transfers/Transfers.vue';
import Swaps from '@/components/TabSlider/Tabs/Swaps/Swaps.vue';
import Action from '@/components/TabSlider/Tabs/Action/Action.vue';
import Reports from '@/components/TabSlider/Tabs/Reports/Reports.vue';
import Expenses from '@/components/TabSlider/Tabs/Expenses/Expenses.vue';
import Statistics from '@/components/TabSlider/Tabs/Statistics/User/Statistics.vue';
import Snackbar from "@/components/Snackbar";

export default {
  name: 'TabSlider',

  components: {
    Overview,
    Sessions,
    Transfers,
    Swaps,
    Action,
    Snackbar,
    Reports,
    Expenses,
    Operations,
    Statistics,
  },

  mixins: [
    AuthMixin
  ],

  data() {
    return this.initialState();
  },

  created() {
    this.computedTab = { tab: 'Visão Geral', key: 1, path: Vue.prototype.$tab_slider.tabs.overview, content: Overview, disabled: false, show: true };
  },

  computed: {
    ...mapGetters({
      selectedTab: 'TabSlider/selectedTab',
      disabledTab: 'TabSlider/disabledTab',
      tabs: 'TabSlider/tabs'
    }),

    computedTab: {
      get() {
        return this.selectedTab;
      },
      set(activeTab) {
        this.tab = activeTab;
        if (activeTab) {
          this.$store.commit('TabSlider/setSelectedTab', activeTab);      
        }
      }
    },

    computedTabs: {
      get() {
        return [
          { tab: 'Visão Geral', key: 1, path: Vue.prototype.$tab_slider.tabs.overview , content: Overview, disabled: false, show: true },
          { tab: 'Operações', key: 2, path: Vue.prototype.$tab_slider.tabs.requests, content: Operations, disabled: false, show: true },
          { tab: 'Sessões', key: 3, path: Vue.prototype.$tab_slider.tabs.sessions, content: Sessions, disabled: false, show: true },
          { tab: 'Transferências', key: 4, path: Vue.prototype.$tab_slider.tabs.transfers, content: Transfers, disabled: false, show: true },
          { tab: 'Swaps', key: 5, path: Vue.prototype.$tab_slider.tabs.swaps, content: Swaps, disabled: false, show: true },
          { tab: 'Reports', key: 6, path: Vue.prototype.$tab_slider.tabs.reports, content: Reports, disabled: false, show: true },
          { tab: 'Despesas', key: 7, path:Vue.prototype.$tab_slider.tabs.expenses, content: Expenses, disabled: false, show: this.userIsRailManager },
          { tab: 'Estatisticas', key: 8, path:Vue.prototype.$tab_slider.tabs.statistics, content: Statistics, disabled: false, show: this.userIsPlayer },
          { tab: 'Ação', key: 9, path: Vue.prototype.$tab_slider.tabs.action, content: Action, disabled: this.disabledTab, show: true }
        ];
      },
      set(tabItems) {
        this.$store.commit('TabSlider/setTabItems', tabItems);      
      }
    }
  },

  methods: {
    initialState() {
      return {
        tab: null
      }
    },
  },
}