var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('DialogEditNetwork',{attrs:{"is-open":_vm.dialogues.editNetwork},on:{"close-dialog":function($event){_vm.dialogues.editNetwork = false},"refresh":function($event){return _vm.refreshNetworks()}}}),_c('DialogRemoveNetwork',{attrs:{"is-open":_vm.dialogues.removeNetwork},on:{"close-dialog":function($event){_vm.dialogues.removeNetwork = false},"refresh":function($event){return _vm.refreshNetworks()}}}),_c('v-card-title',[_c('v-text-field',{staticClass:"d-none d-md-flex",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('div',[_c('v-text-field',{staticClass:"mx-4 d-flex d-md-none",attrs:{"append-icon":"mdi-magnify","label":"Pesquisar","single-line":"","hide-details":""},on:{"input":function($event){return _vm.search()}},model:{value:(_vm.dataTable.search),callback:function ($$v) {_vm.$set(_vm.dataTable, "search", $$v)},expression:"dataTable.search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.getTableHeaders,"items":_vm.networks,"loading":_vm.loading,"search":_vm.dataTable.search,"server-items-length":_vm.networksTotal,"options":_vm.options,"footer-props":_vm.dataTable.footerProps},on:{"click:row":_vm.setActiveItem,"update:options":function (e) { return _vm.optionsComputed = e; }},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('NicknameCard',{attrs:{"image-u-r-l":item.imageURL,"network-name":item.name,"network-id":item.id,"redirect-to":'networks',"copy-to-clipboard":false,"subtitle":false}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"small":"","disabled":!_vm.userIsRailAdmin},on:{"click":function($event){return _vm.openDialogEditNetwork()}}},on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Editar Network")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-2",attrs:{"disabled":!_vm.userIsRailAdmin,"small":""},on:{"click":function($event){return _vm.openDialogRemoveNetwork()}}},on),[_vm._v(" mdi-delete ")])]}}],null,true)},[_c('span',[_vm._v("Remover Network")])])]}},{key:"no-data",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-check-circle-outline","title":"Não existem networks registadas","class-color":"success white--text","class-icon":"white"}})]},proxy:true},{key:"no-results",fn:function(){return [_c('EmptyStateNoData',{attrs:{"icon":"mdi-magnify","title":"Não foram encontrados resultados","message":"Ajuste os termos da sua pesquisa para encontrar mais resultados"}})]},proxy:true},{key:"loading",fn:function(){return [_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})]},proxy:true}])},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"accent","indeterminate":""}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }