import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import StoreMixin from '@/mixins/Store.vue';

Vue.use(Vuex);
Vue.use(StoreMixin);

const state = {
    tournaments: [],
    tournamentsLoading: true,
    selectedTournament: null,
    error: null,
    humanError: null,
    errorStatus: null,
};

const getters = {
    tournaments: (state) => state.tournaments,
    tournamentsLoading: (state) => state.tournamentsLoading,
    selectedTournament: (state) => state.selectedTournament,
    error: (state) => state.error,
    humanError: (state) => state.humanError,
    errorStatus: (state) => state.errorStatus,
};

const mutations = {
    setTournaments: (state, payload) => { state.tournaments = payload },
    setTournamentsLoading: (state, payload) => { state.tournamentsLoading = payload },
    setSelectedTournament: (state, payload) => { state.selectedTournament = payload },
    setError: (state, payload) => { state.error = payload },
    setHumanError: (state, payload) => { state.humanError = payload },
    setErrorStatus: (state, payload) => { state.errorStatus = payload },
    addTournament: (state, payload) => {
        if (!state.tournaments || !state.tournaments.length)
            state.tournaments = [];

        state.tournaments.unshift(payload);
    },
};

const actions = {
    // Fetch the list of tournaments
    async fetchTournaments({ commit }, payload) {
        commit('setTournamentsLoading', true);
        commit('setTournaments', []);
        commit('setError', null);

        let request_url = Vue.prototype.$url_api_live + 'tourneys';

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload.params);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setTournaments', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setTournaments', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            commit('setTournamentsLoading', false);
            return output;
        });
    },

    async fetchTournamentsByEventId({ commit }, payload) {
        commit('setTournamentsLoading', true);
        commit('setTournaments', []);
        commit('setError', null);

        let request_url = Vue.prototype.$url_api_live + `events/${payload.id}/tourneys`;

        delete payload.id;

        request_url = StoreMixin.methods.generateQueryParamsUrl(request_url, payload);

        let output = false;

        return axios.get(request_url)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setTournaments', response.data);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setTournaments', []);
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            commit('setTournamentsLoading', false);
            return output;
        });
    },

    // Create a new tournament
    async createTournament({ commit, dispatch }, payload) {
        // Clear state
        dispatch('clearErrors');

        // Handle payload data
        let data = payload;

        // Configure request
        const request_url = Vue.prototype.$url_api_live + 'tourneys';

        let config = {
            method: 'POST',
            url: request_url,
            headers: {
                'Content-Type': 'application/json'
            },
            data: data
        }

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.data.success;
            if (response.status === 201) {
                commit('addTournament', data);
                output = true;
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', response.status);
            }
        })
        .catch(function (error) {
            commit('setError', error.response ? error.response.data.message : error);
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    // Update a tournament
    async updateTournament({ commit, dispatch }, payload) {

        // Handle payload data
        let data = JSON.stringify(payload);

        // Configure request_url
        const request_url = Vue.prototype.$url_api_live + 'tourneys/' + payload.id;

        // Configure request
        let config = {
            method: 'PUT',
            url: request_url,
            headers: { 
              'Content-Type': 'application/json'
            },
            data : data
        };

        // Execute request & return
        let output = false;

        return axios(config)
        .then(function (response) {
            output = response.status == 200;
            if (output) {
                commit('setSelectedTournament', response.data);
            } else {
                commit('setError', response.data.message);
                commit('setHumanError', response.data.human_message);
            }
        })
        .catch(function (error) {
            commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            commit('setHumanError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            output = false;
        })
        .then(function () {
            return output;
        });
    },

    // Delete a tournament
    async deleteTournament({ commit, dispatch }, tournamentId) {
        commit('setError', null);

        try {
            const response = await axios.delete(Vue.prototype.$url_api_live + `tourneys/${tournamentId}`);
            if (response.status === 204) {
                // Timeout is here like an HAMMER to fix it. In order to keep it smooth when C,U,D and update Table info visually.
                setTimeout(() => {
                    // refresh
                    dispatch('fetchTournaments');
                }, 1500);
            } else {
                commit('setError', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
                commit('setErrorStatus', 'Algo correu mal. Tente mais tarde ou contacte o suporte.');
            }
        } catch (error) {
            commit('setError','Algo correu mal. Tente mais tarde ou contacte o suporte.');
        }
    },
    

    clearErrors({ commit }) {
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },

    resetStore({ commit }) {
        commit('setTournaments', []);
        commit('setTournamentsLoading', false);
        commit('setSelectedTournament', null);
        commit('setError', null);
        commit('setHumanError', null);
        commit('setErrorStatus', null);
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
