var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('DialogAddPlayerPokerTable',{attrs:{"is-open":_vm.dialogues.addPlayer,"players-up":_vm.playersUp,"players-down":_vm.playersDown,"direction":_vm.dialogues.direction,"index":_vm.dialogues.index},on:{"close-dialog":function($event){_vm.dialogues.addPlayer = false}}}),_c('v-sheet',{staticStyle:{"border":"2px solid"},attrs:{"color":"accent"}},[_c('v-card',{staticClass:"overflow-hidden",attrs:{"elevation":"0","tile":"","dark":""}},[_c('v-card-text',[_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex"},_vm._l((3),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"ml-3 mr-3",attrs:{"type":"avatar"}})}),1):_vm._l((_vm.playersUp),function(player,index){return _c('div',{key:player.id,style:(_vm.getPlayersStyle('playersUp')),on:{"click":function($event){return _vm.openDialogAddPlayer(player, 'up', index)}}},[(player && player.name)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-badge',{staticClass:"mx-1 mx-sm-2 mx-md-1 move-badge",staticStyle:{"cursor":"pointer"},attrs:{"avatar":"","overlap":"","bordered":"","bottom":"","color":"white"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":require('@/assets/images/countries/' + _vm.getCodeFromCountry(player.country))}})],1)]},proxy:true}],null,true)},[_c('v-avatar',{style:(_vm.getAvatarStyle(player)),attrs:{"size":_vm.avatarSize + 'px',"color":"accent"}},[(player && player.picture)?_c('v-img',{staticClass:"rounded-circle",attrs:{"src":player.picture}}):(player && !player.picture && player.name)?_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.handlePlayerName(player))+" ")]):_vm._e()],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(player.name))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mx-1 mx-sm-2 mx-md-1",staticStyle:{"cursor":"pointer"},attrs:{"size":_vm.avatarSize + 'px',"color":"grey lighten-2"}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"black"}},[_vm._v(" mdi-account-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar jogador")])])],1)})],2),(_vm.isLoading)?_c('div',{staticClass:"table-container my-5"},[_c('v-skeleton-loader',{staticStyle:{"border-radius":"50px"},attrs:{"type":"card","height":"100px","width":"400px"}})],1):_c('div',{staticClass:"table-container"},[_c('div',{staticClass:"table my-5"},[_c('v-chip',{attrs:{"color":"rgba(0000, 0, 0, 0.7)"}},[_c('v-icon',{attrs:{"color":"white","left":"","small":"","disabled":_vm.totalPlayers <= 2},on:{"click":_vm.decrement}},[_vm._v(" mdi-minus ")]),_c('span',{staticClass:"white--text caption mx-2"},[_vm._v(" "+_vm._s(_vm.totalPlayers)+" Jogadores ")]),_c('v-icon',{attrs:{"color":"white","right":"","small":"","disabled":_vm.totalPlayers >= 12},on:{"click":_vm.increment}},[_vm._v(" mdi-plus ")])],1)],1)]),_c('div',{staticClass:"d-flex justify-center align-center"},[(_vm.isLoading)?_c('div',{staticClass:"d-flex"},_vm._l((3),function(n){return _c('v-skeleton-loader',{key:n,staticClass:"ml-3 mr-3",attrs:{"type":"avatar"}})}),1):_vm._l((_vm.playersDown),function(player,index){return _c('div',{key:player.id,style:(_vm.getPlayersStyle('playersDown')),on:{"click":function($event){return _vm.openDialogAddPlayer(player, 'down', index)}}},[(player && player.name)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('v-badge',{staticClass:"mx-1 mx-sm-2 mx-md-1 move-badge",staticStyle:{"cursor":"pointer"},attrs:{"avatar":"","overlap":"","bordered":"","bottom":"","color":"white"},scopedSlots:_vm._u([{key:"badge",fn:function(){return [_c('v-avatar',[_c('v-img',{attrs:{"src":require('@/assets/images/countries/' + _vm.getCodeFromCountry(player.country))}})],1)]},proxy:true}],null,true)},[_c('v-avatar',{style:(_vm.getAvatarStyle(player)),attrs:{"size":_vm.avatarSize + 'px',"color":"accent"}},[(player && player.picture)?_c('v-img',{staticClass:"rounded-circle",attrs:{"src":player.picture}}):(player && !player.picture && player.name)?_c('span',{staticClass:"font-weight-bold black--text"},[_vm._v(" "+_vm._s(_vm.handlePlayerName(player))+" ")]):_vm._e()],1)],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(player.name))])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-avatar',_vm._g(_vm._b({staticClass:"mx-1 mx-sm-2 mx-md-1",staticStyle:{"cursor":"pointer"},attrs:{"size":_vm.avatarSize + 'px',"color":"grey lighten-2"}},'v-avatar',attrs,false),on),[_c('v-icon',{attrs:{"size":"18","color":"black"}},[_vm._v(" mdi-account-circle ")])],1)]}}],null,true)},[_c('span',[_vm._v("Adicionar jogador")])])],1)})],2)])],1)],1),(_vm.showDialogCardAddPlayer)?_c('DialogAddPlayerPokerTable',{attrs:{"is-open":_vm.dialogues.addPlayer,"players-up":_vm.playersUp,"players-down":_vm.playersDown,"direction":_vm.dialogues.direction,"index":_vm.dialogues.index,"type":'card'},on:{"close-dialog":function($event){_vm.dialogues.addPlayer = false}}}):_vm._e(),(_vm.$vuetify.breakpoint.smAndDown)?_c('PokerTablePlayerInfo',{attrs:{"show":_vm.showPokerTablePlayerInfo},on:{"hide-component":function($event){return _vm.hidePlayerInfoComponent()}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }