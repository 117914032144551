// VALID LOOKUPS - "BOTTOM" / "TOP"
import { mapGetters } from 'vuex';
import EmptyStateNoData from '@/components/EmptyStateNoData';

export default {
    name: 'TablePlayerPnl',

    components: {
        EmptyStateNoData
    },

    props: {
        type: {
            type: String,
            required: false,
            default: 'BOTTOM'
        },
        title: {
            type: String,
            required: false,
            default: 'Titulo'
        },
        filter: {
            type: Number,
            required: false,
            default: 5,
        },
        maxHeight: {
            type: String,
            required: false,
            default: null
          },
          minHeight: {
            type: String,
            required: false,
            default: '588px'
          },
    },
    
    data() {
        return this.initialState();
    },
    
    computed: {
        ...mapGetters({
            statisticsAdmin: 'railStatistics/dataAdmin',
            statisticsLoading: 'railStatistics/loadingDataAdmin',

            topProfitStatistics: 'railStatistics/topProfitStatisticsByPlayer',
            bottomProfitStatistics: 'railStatistics/bottomProfitStatisticsByPlayer',
            loadingTopProfitStatistics: 'railStatistics/loadingTopProfitStatisticsByPlayer',
            loadingBottomProfitStatistics: 'railStatistics/loadingBottomProfitStatisticsByPlayer',

            heroDatePickerValue: 'UI/heroDatePickerValue',
            users: 'users/usersAllActive',
            teamFilter: 'teams/teamFilter',
        }),
        
        heightComputed: function () {
            if (this.maxHeight) {
              return this.maxHeight;
            }
      
            return '588px';
          },
      
          optionsComputed: {
            get: function () {
              return this.options;
            },
            set: function (newValue) {
              if (!this._.isEqual(this.options, newValue)) {
                this.options = newValue;
              }
            }
          },

        dataStatistics: function () {
            if (this.type == 'BOTTOM' && Array.isArray(this.bottomProfitStatistics) && this.bottomProfitStatistics.length > 0) {
                return this.bottomProfitStatistics.filter((item) => item.pnl.EUR.value < 0);
            } else if (this.type == 'TOP' && Array.isArray(this.topProfitStatistics) && this.topProfitStatistics.length > 0) {
                return this.topProfitStatistics.filter((item) => item.pnl.EUR.value > 0);
            }
            return [];
        },
        dataStatisticsLoading: function () {
            return this.type == 'BOTTOM'
                ? this.loadingBottomProfitStatistics
                : this.loadingTopProfitStatistics;
        },

        dataStatisticsTotal: function () {
            if (!this._.isEmpty(this.dataStatistics)) {
                console.log(this.dataStatistics.length);
                return this.dataStatistics.length;
            }
            return 0;
        },

        pnlColor() {
            let color = this.type == 'BOTTOM' ? 'red--text' : 'green--text';
            return color;
        }
    },
    
    watch: {
        heroDatePickerValue: function () {
            this.fetchStatistics();
        },
        teamFilter: function () {
            this.fetchStatistics();
        },
    },

    created() {
        this.fetchUsers();
    },
    
    methods: {
        initialState() {
            return {
                headers: [
                    { text: 'Jogador', value: 'name', sortable: false },
                    { text: 'Resultado', value: 'pnl.value', sortable: false },
                  ],
                  dataTable: {
                    search: null,
                  },
                  options: {
                    page: 1,
                    itemsPerPage: 10,
                    sortBy: [],
                    sortDesc: [],
                    groupBy: [],
                    groupDesc: [],
                    mustSort: true,
                    multiSort: false
                  },
            };
        },

        fetchUsers() {
            // Get users if not already fetched
            if (this._.isEmpty(this.users)) {
                this.$store.dispatch('users/getUsersAllActive');
            }
        },

        /**
         * <router-link> :to prop expects String, Object. Doesn't accept Null values.
         * @param {*} item 
         * @returns String link
         */
        genPlayerLink(item) {
            return item.user
                ? '/players/' + item.user
                : '#';
        },

        /**
         * Retrieves username based on id parameter
         * @param {Number} id 
         * @returns {String} empty or with user name
         */
        handleUsername(id) {
            let output = '';
            let user = this.retrieveUserInfo(id);
            if (user && user.name) {
                output = user.name;
            } else {
                output = 'Utilizador inativo, ID: ' + id;
            }
            return output;
        },

        /**
         * Retrieves user DTO based on id parameter
         * @param {Number} id 
         * @returns {Object}
         */
        retrieveUserInfo(id) {
            let output = {};
            if (!this._.isEmpty(this.users)) {
                output = this.users.find(element => element.id == id);
    
                if (!this._.isUndefined(output)) {
                    output = output;
                }
            }
            return output;
        },
        
        async fetchStatistics() {
            let payload = null;
            if (! this._.isEmpty(this.heroDatePickerValue)) {
                payload = {
                    dateBegin: this.heroDatePickerValue[0],
                    dateEnd: this.heroDatePickerValue[1],
                    page: 1,
                    itemsPerPage: this.filter
                };
            }
            if (this.teamFilter) {
                payload.team = this.teamFilter;
            }
            if (this.type == 'BOTTOM') {
                await this.$store.dispatch('railStatistics/getBottomProfitStatisticsByPlayer', payload);
            } else {
                await this.$store.dispatch('railStatistics/getTopProfitStatisticsByPlayer', payload);
            }
        },

        /**
         * Retrieves user img cutout based on id parameter
         * @param {Number} id 
         * @returns {String} url with imgCutout
         */
        handleUserImgCutout(id) {
            let output = null;
            let user = this.retrieveUserInfo(id);

            if (user && user.imgCutout) {
                output = user.imgCutout;
            }

            return output;
        },
    }
}
