import { mapGetters } from 'vuex';
import AuthMixin from '@/mixins/Auth.vue';
import RailMixin from '@/mixins/Rail.vue';
import DataMixin from '@/mixins/Data.vue';
import EmptyStateNoData from '@/components/EmptyStateNoData';
import LoadingSpinner from '@/components/LoadingSpinner/LoadingSpinner.vue';


export default {

    mixins: [
        AuthMixin,
        RailMixin,
        DataMixin,
    ],

    components: {
        EmptyStateNoData,
        LoadingSpinner,
    },

		props: {
				openNoteDetails: {
					type: Function,
					required: true,
					default: () => {}
				},
        search: {
          type: String,
          required: false,
          default: ''
        },
				openDialogAddEditNotes: {
					type: Function,
					required: true,
					default: () => {}
				},
				openDialogConfirmation:{
					type: Function,
					required: true,
					default: () => {}
				},
		},

		async created(){
      this.fetchData();
    },

    mounted() {
        const table = this.$refs.infiniteScroll.$el.querySelector('.v-data-table__wrapper');
        this.scrollContainer = table;
        table.addEventListener('scroll', this.onScroll);
    },

    beforeDestroy() {
        this.scrollContainer.removeEventListener('scroll', this.onScroll);
				this.$store.commit('livePlayers/resetSelectedPlayerNotesScroll');
    },

    computed: {
        ...mapGetters({
            selectedPlayer: 'livePlayers/selectedPlayer',
            selectedPlayerNotes: 'livePlayers/selectedPlayerNotes',
						selectedPlayerNotesScroll: 'livePlayers/selectedPlayerNotesScroll',
            selectedPlayerNotesScrollLoading: 'livePlayers/selectedPlayerNotesScrollLoading',
        }),

        userId(){
            return this.$route.params.id ? this.$route.params.id : this.selectedPlayer.id;
        }
    },  

    data() {
      return {
        allNotes: [],
        loadingInfiniteScroll: false,
        currentPage: 1,
        isLoading: false,
        options: {
            page: 1,
            itemsPerPage: 10,
        }
      };
    },

    methods: {
      async loadMore() {
        if(this.selectedPlayerNotesScroll.length == (this.options.itemsPerPage * this.options.page)){
            this.loadingInfiniteScroll = true;
            this.options.page += 1;
            await this.fetchData();
            this.loadingInfiniteScroll = false;
        }
      },

      calcHeight() {
        const heights = {
          xs: '300px',
          sm: '400px',
          lg: '500px',
          xl: '650px',
        };

        const currentBreakpoint = Object.keys(heights).find(bp => this.$vuetify.breakpoint[bp]);
        return heights[currentBreakpoint] || '500px';
      },

      skeletonCardsToShow(){
        const skeletonNumbers = {
          xs: 2,
          md: 4,
          lg: 5,
          xl: 5,
        };
        return skeletonNumbers[Object.keys(skeletonNumbers).find(bp => this.$vuetify.breakpoint[bp])] || 4;
      },

      onScroll() {
        const scrollContainer = this.scrollContainer;
        if (scrollContainer.scrollTop + scrollContainer.clientHeight >= scrollContainer.scrollHeight - 1) {
          this.loadMore();
        }
      },

      async fetchData(){
        let payload = {
            params: {
            	id: this.userId,
              offset: (this.options.page - 1) * this.options.itemsPerPage,
              per_page: this.options.itemsPerPage,
            }
        };
        if(this.search) return;
        await this.$store.dispatch('livePlayers/fetchSelectedPlayerNotesScroll', payload);
      },

      userIsNoteOwner(note){
        return note.created_by.id === this.userTrackerId;
      },
    },
  };