import AuthMixin from '@/mixins/Auth';
import Hero from '@/components/Hero/Hero.vue';
import TablePlayerPnl from '@/components/Rail/Statistics/TablePlayerPnl/TablePlayerPnl.vue';
import Snackbar from '@/components/Snackbar';

export default {
  name: 'Profit',

  components: {
    TablePlayerPnl,
    Snackbar,
    Hero,
  },

  mixins: [
    AuthMixin,
  ],

  data () {
    return {}
  },

  async created () {
    await this.allowOnlyRoles(['rail_admin', 'rail_manager', 'tracker_admin', 'tracker_manager', 'super_coach']);
  },

  methods: {}
}