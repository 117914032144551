import { mapGetters } from 'vuex';
import DialogAddPlayerPokerTable from '@/components/Dialog/DialogAddPlayerPokerTable/DialogAddPlayerPokerTable.vue';
import PokerTablePlayerInfo from '@/components/PokerTablePlayerInfo/PokerTablePlayerInfo.vue';

export default {
  components: {
    DialogAddPlayerPokerTable,
    PokerTablePlayerInfo,
  },

  data() {
    return {
      playersUp: [{}],
      playersDown: [{}],
      showPlayerBorder: false,
      windowWidth: window.innerWidth,
      showPokerTablePlayerInfo: false,
      showDialogCardAddPlayer: true,
      isLoading: false,
      dialogues:{
        addPlayer: false,
        direction: null,
        index: null,
      }
    };
  },

  watch: {
    selectedPlayer(newVal, oldVal){
    }
  },

  computed: {
    totalPlayers() {
      return this.playersUp.length + this.playersDown.length;
    },

    ...mapGetters({
      selectedPlayer: 'livePlayers/selectedPlayer',
      countries: 'countries/countries',
      pokerTablePlayers: 'liveGame/pokerTablePlayers',
      pokerTablePlayersLoading: 'liveGame/pokerTablePlayersLoading',
      selectedPokerTablePlayer: 'liveGame/selectedPokerTablePlayer',
    }),

    marginSize() {
      return this.windowWidth * 0.01;
    },

    getPlayersStyle() {
      return (side) => {
          const smallBreakpointStyle = { 'margin-left': '8px', 'margin-right': '8px' };
          const extraSmallBreakpointStyle = { 'margin-left': '5px', 'margin-right': '5px' };
          const defaultStyle = { 'margin-left': this.marginSize + 'px', 'margin-right': this.marginSize + 'px' };
          const autoMarginStyle = { 'margin-left': 'auto', 'margin-right': 'auto' };
  
          if (this.$vuetify.breakpoint.sm) return smallBreakpointStyle;
          if (this.$vuetify.breakpoint.xs) return extraSmallBreakpointStyle;
  
          if (side === 'playersUp' || side === 'playersDown') {
              const players = this[side];
              return players.length > 4 ? autoMarginStyle : defaultStyle;
          }
  
          return {};
      }
    },

    avatarSize() {
      // galaxy fold config
      if (this.windowWidth <= 300) {
        return 28;
      }

      const sizes = { xs: 38, sm: 40, md: 40, lg: 48, xl: 48 };
      return sizes[this.$vuetify.breakpoint.name] || 48;
    }
  },

  created() {
    this.getPokerTable();
    this.$root.$on('remove-player', this.removePlayer);
    window.addEventListener('resize', this.updateWidth);
  },

  destroyed() {
    window.removeEventListener('resize', this.updateWidth);
  },

  provide: function () {
    return {
      clearDirection: this.clearDirection,
      resetPlayers: this.resetPlayers,
      updatePlayersUp: this.updatePlayersUp,
      updatePlayersDown: this.updatePlayersDown,
      getIsTableFull: this.getIsTableFull,
      getAddedPlayers: this.getAddedPlayers,
      isPlayerInTable: this.isPlayerInTable,
    }
  },

  methods: {
    increment() {
      this.addPlayerToCorrectList();
    },
  
    decrement() {
      this.removePlayerFromCorrectList();
    },

    async getPokerTable(){
      await this.$store.dispatch('liveGame/getPokerTable');
      const players = this.pokerTablePlayers ? this.pokerTablePlayers.players : [];

      let playersUp = [{}, {}, {}];
      let playersDown = [{}, {}, {}];

      players.forEach(player => {
        const [ direction, index ] = player.table_position.split('');
        if (direction === 'U') {
          playersUp[Number(index)] = player;
        } else {
          playersDown[Number(index)] = player;
        }
      });

      for (let i = 0; i < playersUp.length; i++) {
        if (!playersUp[i]) {
          playersUp[i] = {};
        }
      }

      for (let i = 0; i < playersDown.length; i++) {
        if (!playersDown[i]) {
          playersDown[i] = {};
        }
      }
      
      this.updatePlayersUp(playersUp);
      this.updatePlayersDown(playersDown);
    },

    getAvatarStyle(player=null) {
      if (this.selectedPlayer && this.selectedPlayer == player && this.showPlayerBorder) {
        return { border: '3px solid #4CAF50 !important' };
      }
      return { border: '2px solid white !important' };
    },
  
    updateWidth() {
      if (window.innerWidth >= 960) {
        this.showPokerTablePlayerInfo = false;
      }
      this.windowWidth = window.innerWidth;
    },

    updatePlayersUp(players) {
      this.playersUp = players;
    },

    updatePlayersDown(players) {
      this.playersDown = players;
    },
    
    setActiveItem(item) {
      if (item) {
        this.$store.commit(
          'livePlayers/setSelectedPlayer',
          item
        );
      }
    },
  
    openDialogAddPlayer(player, direction, index) {
      if(Object.keys(player).length > 0) {
        this.$root.$emit('reset-pagination-table-notes');
        this.$emit('toggle-poker-player-info', player)
                
        let payload = {
          params: {
            id: player && player.id ? player.id : null
          }
        }
        this.$store.commit('livePlayers/setSelectedPlayerTags', player.tags);
        this.$store.dispatch('livePlayers/fetchSelectedPlayerNotes', payload);
        
        this.showPokerTablePlayerInfo = true;
        this.showPlayerBorder = true;
        this.setActiveItem(player);
      }else{
        this.hidePlayerInfoComponent()
        this.setActiveItem(null);
      }

      if (!this.showPokerTablePlayerInfo) {
        Object.assign(this.dialogues, {
          addPlayer: true,
          direction,
          index,
        });
      }
      
      if (this.showPokerTablePlayerInfo && this.$vuetify.breakpoint.smAndDown) {
        this.showDialogCardAddPlayer = false;
      }

      this.showPokerTablePlayerInfo = Boolean(player && player.id);
      this.$emit('change-fab-button', this.showPokerTablePlayerInfo ? 'notes' : 'players');
    },
  
    hidePlayerInfoComponent() {
      this.$emit('change-fab-button', 'players');
      this.showPokerTablePlayerInfo = false;
      this.showDialogCardAddPlayer = true;
    },

    resetPlayers() {
      this.setActiveItem(null);
      this.$emit('toggle-poker-player-info', null)
      this.showPlayerBorder = false;

      if (Array.isArray(this.playersUp)) {
          this.playersUp = this.playersUp.map(() => ({}));
      }
  
      if (Array.isArray(this.playersDown)) {
          this.playersDown = this.playersDown.map(() => ({}));
      }

      // update api
      const payload = {
        id: this.pokerTablePlayers.id,
        players: [],
        owned_by: this.pokerTablePlayers.owned_by,
      }
      this.$store.dispatch('liveGame/updatePokerTable', payload);
    },

    async removePlayer(player) {
      const indexUp = this.playersUp.findIndex(p => p.id === player.id);
      const indexDown = this.playersDown.findIndex(p => p.id === player.id);

      if (indexUp !== -1) {
          this.$set(this.playersUp, indexUp, {});
      }
  
      if (indexDown !== -1) {
          this.$set(this.playersDown, indexDown, {});
      }

      // update api
      const newPlayers = this.pokerTablePlayers.players.filter(tablePlayer => tablePlayer.id !== player.id);

      let payload = {
        id: this.pokerTablePlayers.id,
        players: newPlayers,
        owned_by: this.pokerTablePlayers.owned_by,
      }

      await this.$store.dispatch('liveGame/updatePokerTable', payload);
    },
  
    clearDirection() {
      this.dialogues.direction = null;
      this.setActiveItem(null);
    },
    
    addPlayerToCorrectList() {
      const newPlayer = {};
      
      if (this.playersUp.length >= this.playersDown.length + 1) {
        this.playersDown.push(newPlayer);

        return;
      } else if (this.playersDown.length >= this.playersUp.length + 1) {
        this.playersUp.push(newPlayer);
        
        return;
      }
      this.isEven(this.totalPlayers) ? this.playersUp.push(newPlayer) : this.playersDown.push(newPlayer);
    },
    
    removePlayerFromCorrectList() {
      let player;

      if (this.playersUp.length >= this.playersDown.length + 1) {
        player = this.playersUp.pop();
      } else if (this.playersDown.length >= this.playersUp.length + 1) {
        player = this.playersDown.pop();
      } else {
        player = this.isEven(this.totalPlayers) ? this.playersDown.pop() : this.playersUp.pop();
      }

      if (player.id) {
        this.$root.$emit('clear-players', player);
        this.removePlayer(player);
      }
    },
  
    isEven(number) {
      return number % 2 === 0;
    },

    getAddedPlayers() {
      return this.playersUp.concat(this.playersDown).filter(player => player.id !== undefined);
    },

    getIsTableFull() {
      const addedPlayers = this.getAddedPlayers();
      
      return this.totalPlayers <= addedPlayers.length;
    },

    isPlayerInTable(playerId) {
      return this.getAddedPlayers().some(p => p.id === playerId);
    },

    handlePlayerName(user) {
      let output = '';
  
      if (user && user.name) {
          // Divide o nome em palavras
          const names = user.name.split(' ');
          
          // Pega a primeira letra dos dois primeiros nomes
          if (names.length > 1) {
              output = names[0][0] + names[1][0];
          } else if (names.length === 1) {
              output = names[0][0];
          }
      }
  
      return output.toUpperCase(); // Converte para maiúsculas
    },

    getCodeFromCountry(name) {
      let output = 'unknown.svg';

      if (!this._.isEmpty(this.countries) && name) {
          let countryObject = this.countries.find(e => e.name == name);
          if (countryObject) {
              output = countryObject.code + '.svg'
          }
      }

      return output;
  },
  }
};